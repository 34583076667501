<template>
  <div class="favorites-page__no-products">
    <p class="favorites-page__no-products__title">
      В избранном пока нет товаров
    </p>
    <p class="favorites-page__no-products__text">
      Посмотрите наши <nuxt-link to="/catalog/filters/badge-novinka/">
        новинки
      </nuxt-link>
      или воспользуйтесь <a href="#" @click.prevent="onSearchClick">поиском</a>,
      если ищете что-то конкретное
    </p>
    <Button :to="{ name: 'catalog' }" class="favorites-page__no-products__go-to-catalog" @click="handleGoToCatalog">
      Перейти к каталогу
    </Button>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
import Button from '@/components/elements/Button.vue';
export default {
  name: 'FavoritesNoProducts',
  components: {
    Button,
  },
  layout: 'main',
  methods: {
    ...mapActions('search', ['setIsOpenedSearch']),
    handleGoToCatalog() {
      this.$router.push('/catalog');
    },
    onSearchClick() {
      // this.$refs.searchInput.focus();
      this.setIsOpenedSearch(true)
    },
  },
}
</script>
