<template>
  <section class="product-grid">
    <h2 class="visually-hidden">
      Каталог
    </h2>
    <template v-if="products.items.length">
      <LazyLoader
        v-if="products.items.length"
        :loading="isPending"
        :next-page="canGetNextPage"
        @update="onLoaderUpdate"
      >
        <client-only>
          <ul
            class="product-grid__list"
            :class="{
              'product-grid__list-4': columns4
            }"
          >
            <li
              v-for="product in products.items.slice(0, products.items.length - (products.items.length % 4))"
              :key="`${!product.isBannerItem ? product.id : `${product.id}-banner`}`"
              class="product-grid__item"
            >
              <ProductCard
                v-if="!product.isBannerItem"
                :item="product"
                :page-type="pageType"
                @toggle-favorite="onToggleFavorite"
              />
              <Banner v-else :info="product" :ratio="bannerRatio" />
            </li>
          </ul>
          <ul
            class="product-grid__list product-grid__center"
            :class="{
              'product-grid__list-4': columns4
            }"
          >
            <li
              v-for="product in products.items.slice(products.items.length - (products.items.length % 4))"
              :key="`${!product.isBannerItem ? product.id : `${product.id}-banner`}`"
              class="product-grid__item"
            >
              <ProductCard
                v-if="!product.isBannerItem"
                :item="product"
                :page-type="pageType"
                @toggle-favorite="onToggleFavorite"
              />
              <Banner v-else :info="product" :ratio="bannerRatio" />
            </li>
          </ul>
        </client-only>
      </LazyLoader>
      <!--      <div v-show="addingCartLoading" class="product-grid__overlay"></div>-->
    </template>
    <template v-else>
      <FavoritesNoProducts v-if="pageType === 'favorites'" />
      <span v-else>Товары не найдены</span>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import ProductCard from '@/components/catalog/productCard';
import LazyLoader from '@/components/elements/LazyLoader';
import './index.css';
import Banner from '~/components/catalog/banner';
import FavoritesNoProducts from '~/components/catalog/favoritesNoProducts/index.vue';

export default {
  name: 'ProductsLazyLoad',
  components: {
    FavoritesNoProducts,
    Banner,
    ProductCard,
    LazyLoader,
  },
  layout: 'main',
  props: {
    products: {
      type: Object,
      required: true,
    },
    pageType: {
      type: String,
      default: 'catalog',
    },
    columns4: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPending: false,
      bannerRatio: {
        pc: '100%',
        mobile: '100%',
      },
    }
  },
  computed: {
    ...mapState('cart', ['addingCartLoading']),
    canGetNextPage() {
      return this.products.items.length < this.products.range;
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
  },
  watch: {
    products: {
      handler() {
        this.isPending = false;
      },
      deep: true,
    },
  },
  methods: {
    onLoaderUpdate() {
      this.isPending = true;
      this.$emit('get-items');
    },
    onToggleFavorite(id) {
      this.$emit('toggle-favorite', id);
    },
  },
};
</script>
