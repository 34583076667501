<template>
  <section class="favorites-page container">
    <header class="favorites-page__header">
      <h1 class="title favorites-page__title font font_title-l">
        Избранное
        <span v-if="!$fetchState.pending && totalProducts" class="favorites-page__count font font_sm font_grey">
          {{ totalProductsPhrase }}
        </span>
      </h1>
    </header>

    <template v-if="!$fetchState.pending">
      <ProductsLazyLoad
        class="favorites-page__products"
        columns4
        :products="products"
        page-type="favorites"
        @get-items="getNextPageItems"
        @toggle-favorite="onToggleFavorite"
      />
      <div v-show="addingCartLoading" class="favorites-page__overlay" />
    </template>
    <LoaderDots v-else />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductsLazyLoad from '@/components/catalog/productsLazyLoad';
import LoaderDots from '@/components/elements/LoaderDots';
import { sortOptionsListFavorites } from '@/plugins/enums/sort';
import { phraseDeclension } from '@/plugins/formatting';
import pageMeta from '~/mixins/pageMeta';

export default {
  name: 'FavoritesPage',
  components: {
    ProductsLazyLoad,
    LoaderDots,
  },
  mixins: [pageMeta],
  middleware: ['checkProfile'],
  asyncData() {
    return {
      pageTitle: 'Избранное',
    };
  },
  data() {
    const currentSortOption = sortOptionsListFavorites.find((option) => {
      const isEqualOrderField = option.value === this.$route.query.orderField;
      const isEqualOrderDirection = option.direction === this.$route.query.orderDirection;

      if (this.$route.query.orderField && this.$route.query.orderDirection) {
        return isEqualOrderField && isEqualOrderDirection;
      } else if (this.$route.query.orderField || this.$route.query.orderDirection) {
        return isEqualOrderField || isEqualOrderDirection;
      }

      return option.id === 1;
    });

    return {
      sortOptionsListFavorites,
      currentSortOption,
      products: {},
      paramsFetchItems: null,
      totalProducts: 0,
      productTextVariants: ['товар', 'товаров', 'товара'],
    };
  },
  async fetch() {
    try {
      await this.getCartItems();
      this.paramsFetchItems = {
        pageNum: 1,
        perPage: 12,
        orderField: this.currentSortOption.value,
        orderDirection: this.currentSortOption.direction,
      };
      const result = await this.$api.favorites.getFavorites(this.paramsFetchItems);
      this.products = result;
      this.totalProducts = result.range;
    } catch (error) {
      console.error(error);
      this.products = {
        items: [],
        range: 0,
      };
      this.totalProducts = 0;
    }
  },
  computed: {
    ...mapState('cart', [
      'addingCartLoading',
    ]),
    ...mapGetters('favorites', [
      'productInFavorites',
    ]),
    totalProductsPhrase() {
      return this.phraseDeclension(this.totalProducts, this.productTextVariants);
    },
  },
  watch: {
    '$route.query'() {
      this.$fetch();
    },
    currentSortOption(newSortParam, oldSortParam) {
      if (newSortParam.id !== oldSortParam.id) {
        const itemsFiltersURL = {
          orderField: newSortParam.value,
          orderDirection: newSortParam.direction,
        };
        this.$router.replace({ query: itemsFiltersURL });
      }
    },
  },
  methods: {
    ...mapActions({
      deleteFavoritesAll: 'favorites/deleteFavoritesAll',
      getCartItems: 'cart/getCartItems',
    }),
    phraseDeclension,
    async getNextPageItems() {
      try {
        const nextPageNumber = this.paramsFetchItems.pageNum + 1;
        this.$set(this.paramsFetchItems, 'pageNum', nextPageNumber);

        const { items } = await this.$api.favorites.getFavorites(this.paramsFetchItems);
        this.products.items.push(...items);
      } catch (error) {
        console.error(error);
      }
    },
    onToggleFavorite(id) {
      if (this.productInFavorites(id)) {
        this.totalProducts--;
        this.products.items = this.products.items.filter((item) => item.productId !== id);
      }
    },
  },
};
</script>
